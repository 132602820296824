.container {
  width: 100%;
  background-color: var(--clr-secondary);
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  top: 0;
  position: sticky;
  z-index: 2;
  box-shadow: var(--shadow-4);
}
.container svg {
  vertical-align: middle;
}
.container button {
  text-transform: none;
  background-color: transparent;
  color: var(--clr-header);
}
.container button:hover {
  box-shadow: none;
}
.logo {
  width: 4rem;
  height: 4rem;
}
.logo img {
  width: 3rem;
  height: 3rem;
}
.allJobs, .account, .sidebar, .logo, .theme, .auth {
  height: 3em;
  transition: var(--transition);
  display: grid;
  place-self: center;
}
.allJobs a div, .theme, .auth {
  display: grid;
  place-self: center;
  grid-template-columns: auto auto;
}
.auth button {
  margin: 0 1em;
}
.account button div {
  display: grid;
  place-self: center;
  grid-template-columns: auto auto auto;
}
.account {
  position: relative;
}
.icon {
  display: grid;
  grid-template-columns: auto auto 1fr;
}
.allJobs:hover, .theme:hover, .auth button:hover {
  transform: scale(1.1);
}
.sidebar {
  display: none;
}
.details {
  position: absolute;
  top: 3.5rem;
  display: block;
  background-color: #fff;
  box-shadow: var(--shadow-3);
  z-index: 2;
  width: 100%;

}
.details li {
  text-decoration: none;
  transition: var(--transition);
  padding: 0.5em 0.5em;
}
.details li:hover {
  padding: 0.5em 0.8em;
  background-color: var(--clr-grey-8);
}
.hide {
  display: none;
}
@media only screen and (max-width: 725px) {
  .sidebar {
    display: grid;
    place-self: center;
    grid-template-rows: 1fr;
  }
  .account, .auth {
    display: none;
  }
  .container {
    display: flex;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 450px) {
  .allJobs {
    display: none;
  }
}