.container {
  display: grid;
  place-items: center;
  top: 0;
  right: 0;
  height: 80vh;
  width: 100%;
}
.link a {
  width: 100%;
  padding: 1em 2em;
  text-transform: uppercase;
  color: var(--clr-white);
  transition: var(--transition);
}
.link a:hover {
  box-shadow: var(--shadow-3);
}