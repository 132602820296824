.container {
  background-color: var(--clr-white);
}
.sidebar {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.7);
}
.hide {
  display: none;
}