.container {
  margin: 5em auto;
  width: 90vw;
  max-width: 500px;
}
.container form {
  width: 100%;
  background-color: var(--clr-grey-8);
  padding: 2em;
  border-radius: 20px;
  margin: 0;
}
.container h2 {
  text-align: center;
}
.field {
  margin: 2em 0;
}
.container input {
  width: 100%;
  border-radius: 0%;
  border: none;
  padding: 0.5em;
}
.container input {
  font-size: 1.3em;
}
.container .btn button {
  width: 100%;
  padding: 0.7em;
  text-align: center;
  background-color: var(--clr-primary);
  color: var(--clr-white);
  transition: 0.2s ease-in-out;
  font-size: 1.2em;
}
.container .btn button:hover {
  box-shadow: var(--shadow-3);
}
