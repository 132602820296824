.container {
  background-color: var(--clr-secondary);
  max-width: 100%;
  padding: 0.3em;
  color: var(--clr-grey-1);
}
.container a {
  color: var(--clr-grey-1);
}
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.list-container {
  text-align: center;
  padding: 0.5em;
}
.list-container h5 {
  letter-spacing: 0.1rem;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
.list li {
  text-align: center;
}
.list a {
  display: inline;
  width: fit-content;
}
.list a:hover {
  text-decoration: underline;
}
.icons {
  display: flex;
  justify-content: space-around;
  gap: 0.5em;
  padding: 0.5em;
}
.flex-icons {
  display: none;
}
.credits {
  display: flex;
  justify-content: space-between;
  padding: 2em 0.5em;
}
.credits div {
  padding: 1em;
  text-align: center;
}
.credits div span {
  font-weight: bold;
}
@media only screen and (max-width: 700px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2em;
  }
  .icons-container {
    display: none;
  }
  .flex-icons {
    display: flex;
    justify-content: space-evenly;
    padding: 2em;
  }
  .credits {
    display: block;
  }
}