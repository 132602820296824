.container {
  margin: 5em;
  text-align: center;
}
.flex {
  margin: 3em;
  display: flex;
  justify-content: space-around;
}
.flex a {
  padding: 1em 2em;
  background-color: var(--clr-primary-8);
  text-transform: uppercase;
  transition: var(--transition);
  box-shadow: 2px 2px 2px var(--clr-primary-5);
}
.flex a:hover {
  box-shadow: 5px 5px 5px var(--clr-primary-5);
}