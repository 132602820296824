@import url(https://fonts.googleapis.com/css?family=Open+Sans|Roboto:400,700&display=swap);
:root {
  /* primary color */
  --clr-backgroud: #fffffe;
  --clr-headline: #094067;
  --clr-paragraph: #5f6c7b;
  --clr-btn: #3da9fc;
  --clr-btn-text: #fffffe;
  --clr-stroke: #094067;
  --clr-main: #fffffe;
  --clr-primary: #3da9fc;
  --clr-secondary: #90b4ce;
  --clr-tertiary: #ef4565;
  /* --clr-primary-1: #082f49;
  --clr-primary-2: #0c4a6e;
  --clr-primary-3: #0369a1;
  --clr-primary-4: #0284c7;
  --clr-primary-5: #0ea5e9;
  --clr-primary-6: #38bdf8;
  --clr-primary-7: #7dd3fc;
  --clr-primary-8: #bae6fd;
  --clr-primary-9: #e0f2fe;
  --clr-primary-10: #f0f9ff;
  /* secondary color */
  /* --clr-secondary-1: #431407;
  --clr-secondary-2: #9a3412;
  --clr-secondary-3: #c2410c;
  --clr-secondary-4: #ea580c;
  --clr-secondary-5: #f97316;
  --clr-secondary-6: #fb923c;
  --clr-secondary-7: #fdba74;
  --clr-secondary-8: #fed7aa;
  --clr-secondary-9: #ffedd5;
  --clr-secondary-10: #fff7ed; */
  /* gray nuances */
  --clr-grey-1: #0a0a0a;
  --clr-grey-2: #111827;
  --clr-grey-3: #1f2937;
  --clr-grey-4: #374151;
  --clr-grey-5: #6b7280;
  --clr-grey-6: #9ca3af;
  --clr-grey-7: #d1d5db;
  --clr-grey-8: #e5e7eb;
  --clr-grey-9: #f3f4f6;
  --clr-grey-10: #f3f4f6;
  --clr-white: #fff;
  --clr-black: #000;
  /* validation colours */
  --clr-red: hsl(0, 75%, 48%);
  --clr-green: hsl(125, 76%, 52%);
  /* fonts */
  --header: 'Pilcrow Rounded';
  --normal: 'Hind';
  --ff-primary: Roboto, sans-serif;
  --ff-secondary: Open Sans, sans-serif;
  /* shadows */
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  /* others */
  --transition: all 0.1s linear;
  --spacing: 0.25rem;
  --radius: 0.5rem;
  --max-width: 1100px;
  --mobile-width: 600px;
}
/* =============== 
Global Styles
=============== */
*, ::after, ::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* border: solid 1px black; */
}
body {
  font-family: var(--normal), var(--ff-primary);
  background: var(--clr-backgroud);
  color: var(--clr-headline);
  line-height: 1.5;
  font-size: 1rem;
}
form {
  margin: 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
div {
  white-space: pre-wrap;
}
a {
  text-decoration: none;
  color: var(--clr-headline);
}
img {
  width: 100%;
  display: block;
  object-fit: cover;
}
textarea {
  padding: 1em;
  line-height: normal;
  text-indent: 0.5em;
  letter-spacing: normal;
  font-size: 1.1em;
  border: 2px solid var(--clr-grey-1);
  resize: none;
}
textarea:focus {
  border-color: transparent;
  border-radius: 5px;
  outline: 3px solid var(--clr-primary);
}
h1, h2, h3, h4, h5 {
  letter-spacing: var(--spacing);
  line-height: 1.3;
  margin: 0;
  font-family: var(--header), var(--ff-secondary);
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-paragraph);
}
label {
  cursor: pointer;
}
input {
  padding: 0.5em 1em;
  background-color: var(--clr-white);
}
input:focus {
  outline: none;
}
::placeholder {
  color: var(--clr-paragraph);
  font-family: inherit;
}
button {
  text-transform: uppercase;
  background: var(--clr-btn);
  color: var(--clr-btn-text);
  letter-spacing: var(--spacing);
  transition: var(--transition);
  cursor: pointer;
  border: none;
  font-family: inherit;
  /* padding: 0.5em 1em; */
}
.hide {
  display: none;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.loading {
  width: 6rem;
  height: 6rem;
  border: 5px solid var(--grey-400);
  border-radius: 50%;
  border-top-color: var(--primary-500);
  animation: spinner 0.6s linear infinite;
  margin: 0 auto;
}
@media screen and (max-width: 500px) {
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.5rem;
  }
}