.sidebar {
  position: fixed;
  width: 90%;
  height: 100vh;
  z-index: 10;
  transition-duration: 0.5s;
  top: 0;
  background-color: var(--clr-secondary);
}
.sidebar button {
  background-color: transparent;
  color: var(--clr-headline);
}
.header {
  display: flex;
  justify-content: space-between;
  height: 5rem;
  padding: 1em;
}
.logo img {
  height: 3rem;
  width: 3rem;
}
.close {
  display: grid;
  place-self: center;
  margin-right: 1em;
}
.close button:hover {
  box-shadow: none;
}
.account button {
  width: 100%;
  display: grid;
  place-self: center;
  grid-template-columns: auto auto auto 1fr;
  text-transform: none;
}
.auth button, .auth a {
  width: 100%;
  text-transform: none;
  text-align: left;
}
.link-container, .account, .auth {
  max-width: 100%;
  transition: var(--transition);
  padding: 0.5rem;
  border-top: 2px solid black;
}
.auth {
  border-bottom: 2px solid black;
}
.link-container:hover, .account:hover, .auth:hover {
  text-indent: 0.5em;
}
.flex {
  display: flex;
  justify-content: space-around;
  width: fit-content;
}
a {
  display: grid;
  place-self: center;
}
.hide {
  transform: translate(-100%);
}
.hideDetails {
  display: none;
}