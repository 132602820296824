.slideshow-container {
  position: relative;
  width: 100%;
  height: 45vh;
  overflow: hidden;
  margin: 0 auto;
}
.slideshow {
  width: 100%;
  transition: all 0.5s ease;
}
.slide {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 2em 10%;
}
.hide {
  display: none;
}
.show {
  margin: auto;
  padding: 3em;
  width: 70%;
}
.prev, .next {
  background-color: transparent;
  position: absolute;
  color: var(--clr-grey-2);
  padding: 2rem auto;
  transition: all 0.5s;
  height: 100%;
}
.prev {
  left: 0;
}
.next {
  right: 0;
}
.prev:hover, .next:hover {
  background-color: rgba(134, 135, 134, 0.219);
}
.btn a {
  padding: 1em;
  margin: auto;
  width: 100%;
  color: var(--clr-btn-text);
  font-size: 1em;
}
.btn:hover {
  box-shadow: var(--shadow-3);
}
.register {
  display: flex;
  justify-content: space-around;
  padding-bottom: 1em;
  margin-top: 2em;
}
.filled {
  background-color: var(--clr-grey-8);
  padding: 2em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
}
.filled div {
  margin: auto;
}
.aboutMe {
  padding: 2em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
}
.aboutMe div {
  margin: auto;
}
.image img {
  width: 18em;
  height: 20em;
  object-fit: contain;
}

@media screen and (max-width: 700px) {
  .slideshow-container {
    height: 60vh;
  }
  .filled {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
  }
  .aboutMe {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
  }
  .image img {
    height: 12em;
    width: 14em;
  }
}